import React from "react"
import Image from "gatsby-image"
import styles from "./section.module.css"

export default ({ section, critical }) => {
  const fluid = section.image ? section.image.localFile.childImageSharp : null

  const icon = section.dividerIcon
    ? section.dividerIcon.localFile.publicURL
    : null

  const className = icon
    ? [styles.section, styles.sectionWithIcon].join(" ")
    : styles.section

  return (
    <section className={className}>
      {section.image ? (
        <>
          <div className={styles.desktopImage}>
            <Image critical={critical} alt="" fluid={fluid.desktop} />
          </div>
          <div className={styles.mobileImage}>
            <Image critical={critical} alt="" fluid={fluid.mobile} />
          </div>
        </>
      ) : null}
      <div className={styles.textBox}>
        <h2 className={styles.title}>{section.title}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: section.body.childMarkdownRemark.html,
          }}
        />
      </div>
      {icon ? <img alt="" className={styles.divider} src={icon} /> : null}
    </section>
  )
}
