import React from "react"
import { graphql } from "gatsby"

import Section from "../components/section"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data: { page } }) => (
  <Layout>
    <SEO
      title={page.title}
      description={
        page.metaDescription ? page.metaDescription.metaDescription : null
      }
      image={
        page.sections[0]
          ? page.sections[0].image.localFile.childImageSharp
          : null
      }
      keywords={page.metaKeywords}
    />
    {page.sections.map((section, i) => (
      <Section key={i} critical={i === 0} section={section} />
    ))}
  </Layout>
)

export default IndexPage

export const query = graphql`
  {
    page: contentfulHomepage {
      id
      title
      metaKeywords
      metaDescription {
        metaDescription
      }
      sections {
        title
        dividerIcon {
          localFile {
            publicURL
          }
        }
        image {
          localFile {
            childImageSharp {
              desktop: fluid(
                maxWidth: 1920
                maxHeight: 1260
                cropFocus: CENTER
              ) {
                ...GatsbyImageSharpFluid
              }
              mobile: fluid(maxWidth: 768, maxHeight: 700, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        body {
          body
          childMarkdownRemark {
            html
          }
        }
      }
      footerAddress {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
